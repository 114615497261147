const slugify = require('./slugify');

module.exports = {
  createPaginaInternaPath: (snodo, page) => {
    return `/${slugify(snodo.titolo)}/${slugify(page.titolo || page.titoloLista)}/`;
  },
  createParagrafoId: (paragrafo) => slugify(paragrafo.titolo)
}


