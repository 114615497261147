// Copiato da https://github.com/react-bootstrap/react-overlays/blob/v1.2.0/src/utils/getDocumentHeight.js
/**
 * Get the height of the document
 *
 * @returns {documentHeight: number}
 */

const getDocumentHeight = (doc) => {
  return Math.max(
    doc.documentElement.offsetHeight || 0,
    doc.height || 0,
    doc.body.scrollHeight || 0,
    doc.body.offsetHeight || 0
  );
};
export default getDocumentHeight; 