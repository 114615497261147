import React from 'react';
import axios from 'axios';
import qs from 'qs';
import dicorrado from "../images/people/enrico-dicorrado.png"
import { Link } from './link'
import slug from 'slug';
import ReCAPTCHA from "react-google-recaptcha"

const CAPTCHA_CLIENT_KEY = process.env.GATSBY_CAPTCHA_CLIENT_KEY;

class ContattiInterna extends React.Component {
  
  constructor() {
    super();
    this.captchaRef = React.createRef();
    const url = typeof window !== 'undefined' ? window.location.pathname : '';
    this.state = {
      nome: '',
      cognome: '',
      email: '',
      azienda: '',
      ruolo: '',
      telefono: '',
      messaggio: '',
      privacy: '',
      status: '',
      send: false,
      source: 'AL',
      loc: url
    };
  }

  onChange = (e) => {
    const state = this.state;
    
    state[e.target.name] = e.target.value;
    state['status'] = '';
    this.setState(state);
  }

  getIdSubmitButton = () => {
    if (typeof window !== `undefined`) {
      const pagepath = this.state.loc.split('/');
      const pagename = pagepath[pagepath.length - 2];
      return slug(pagename);
    } else {
      return "contatti";
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.send) {
      const captcha = this.captchaRef.current.getValue();
      this.setState({
        status: '',
        send: true
      }); 
      // get our form data out of state
      const allData = {...this.state, captcha }
      const formData = qs.stringify(allData);
      this.captchaRef.current.reset();
      
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };
      axios.post('https://2m3iqjx1gl.execute-api.eu-west-1.amazonaws.com/prod/form/submit', formData, axiosConfig )
      .then((result) => {
        this.setState({
          status: 'ok',
          send: false
        });  
        if (process.env.NODE_ENV === `production` && window.dataLayer) {
          window.dataLayer.push({'event' : 'formSubmitted', 'formName' : 'contatti'});
        }
      });
    } else {
       this.setState({
          status: '',
          send: false
        });  
      console.log("sending");
    }
  }

  render() {
    const { nome, cognome, email, azienda, ruolo, telefono, messaggio, privacy, status, send  } = this.state;
    return(
      <div>
        {status!=='' ? (
          <div className="row">
            <div className="col-md-12 col-lg-8 offset-lg-2">
              <div className="text-center">
                <h2 className="section__title mb-2">Grazie!</h2>
                <p>A breve prenderemo contatto con te per un primo scambio di informazioni.</p>
                <p>
                  <img alt="Enrico Di Corrado" src={dicorrado} />
                </p>
                <p>  
                  <strong>Enrico Di Corrado</strong><br/>
                  <span>Head of Sales</span>
                </p>
              </div>
            </div>
          </div>
        ):(
          <div className="row">
            <div className="col-md-12 col-lg-8 offset-lg-2">
              <div className="row">
                <div className="col-12">
                  <h2 className="section__title mb-2">Contattaci</h2>
                  <p>
                    Vuoi che il tuo progetto sia un successo? <br/> Scrivici per raccontarci le tue esigenze e trovare insieme la soluzione migliore! 
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={this.onSubmit} action="#" className="form mt-4" method="post" name="contatti" id="formContatti">
                    <fieldset>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="box-group">
                            <div className="box-group__title">Nome *</div>
                            <input required type="text" className="box-group__text" value={nome} onChange={this.onChange}  name="nome" aria-label="nome"/>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="box-group">
                            <div className="box-group__title">Cognome *</div>
                            <input required type="text" className="box-group__text" value={cognome} onChange={this.onChange}  name="cognome" aria-label="nome"/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="box-group">
                            <div className="box-group__title">Email *</div>
                            <input required type="email" className="box-group__text" value={email} onChange={this.onChange} name="email" aria-label="email"/>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="box-group">
                            <div className="box-group__title">Telefono</div>
                            <input type="text"  className="box-group__text"  onChange={this.onChange}  value={telefono} name="telefono" aria-label="telefono"/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="box-group">
                            <div className="box-group__title">Ruolo</div>
                            <input type="text" className="box-group__text" value={ruolo} onChange={this.onChange} name="ruolo" aria-label="ruolo"/>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="box-group">
                            <div className="box-group__title">Azienda *</div>
                            <input required type="text" className="box-group__text" value={azienda} onChange={this.onChange} name="azienda" aria-label="azienda"/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="box-group">
                            <div className="box-group__title">Messaggio *</div>
                            <textarea className="box-group__textarea" id="messaggio" onChange={this.onChange} name="messaggio" value={messaggio} aria-label="messaggio"></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <label className="checkbox">
                            Dichiaro di aver letto la <Link to="/privacy/" title="vai alla privacy policy">privacy policy</Link> e acconsento al trattamento dei miei dati personali *
                            <input type="checkbox" required id="privacy" name="privacy" value={privacy} />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <ReCAPTCHA ref={this.captchaRef} sitekey={CAPTCHA_CLIENT_KEY}/>
                        </div>
                      </div>
                      <div className="text-center">
                        <input id={this.getIdSubmitButton()} disabled={send} type="submit" className="btn-link submit" value="Invia" />
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ContattiInterna;
